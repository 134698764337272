@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 22px !important;
  color: #DA452C;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 10px 20px;
  background: transparent;
  font-weight: 900 !important;
}


.about-us * {
  box-sizing: border-box;
}

.about-us {
  background: #ffffff;
  width: 1440px;
  height: 4596px;
  position: relative;
  overflow: hidden;
}

.frame-37845 {
  width: 1440px;
  height: 170px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rectangle-4700 {
  background: #da452c;
  width: 1440px;
  height: 70px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rectangle-47002 {
  background: #e9e9e9;
  width: 1440px;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 70px;
}

.frame-5 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 80px;
  top: 19px;
}

.carbon-email-new {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.bestagency-gmail-com {
  flex-shrink: 0;
  width: 204px;
  height: 29px;
  position: relative;
}

.infi-nanoqodee-com {
  color: var(--gray6, #f2f2f2);
  text-align: left;
  font: 400 18px/160% "Inter";
  position: absolute;
  left: 0px;
  top: 0px;
}

.group-38583 {
  width: 449px;
  height: 26px;
  position: static;
}

.bx-home-alt-2 {
  position: absolute;
  left: 495px;
  top: 22px;
  overflow: visible;
}

._888-sargent-ave-winnipeg--manitoba--r3e-0c7--canada {
  color: var(--gray6, #f2f2f2);
  text-align: left;
  font: 400 16px/160% "Inter";
  position: absolute;
  left: 525px;
  top: 20px;
}

.frame {
  width: 194px;
  height: 21px;
  position: absolute;
  left: 1166px;
  top: 25px;
  overflow: hidden;
}

.group {
  width: 193.99px;
  height: 21px;
  position: static;
}

.group2 {
  position: absolute;
  left: 171.07px;
  top: 3.94px;
  overflow: visible;
}

.group3 {
  width: 20.39px;
  height: 18.91px;
  position: static;
}

.group4 {
  position: absolute;
  left: 82.52px;
  top: 1.05px;
  overflow: visible;
}

.group5 {
  position: absolute;
  left: 87.49px;
  top: 5.65px;
  overflow: visible;
}

.vector7 {
  position: absolute;
  left: 97.9px;
  top: 3.17px;
  transform: translate(0.31px, -0.43px);
  overflow: visible;
}

.group6 {
  position: absolute;
  left: 0px;
  top: 3.23px;
  overflow: visible;
}

.group7 {
  position: absolute;
  left: 43.82px;
  top: 0px;
  overflow: visible;
}

.group8 {
  position: absolute;
  left: 127.39px;
  top: 2.44px;
  overflow: visible;
}

.nanoqode---logo-5-1 {
  width: 246px;
  height: 91px;
  position: absolute;
  left: 57px;
  top: 76px;
}

.nav-button {
  background: #5aa6b1;
  border-radius: 4px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 200px;
  position: absolute;
  left: 1159.5px;
  top: 91px;
}

/* .-1--800--361-2961 {
  color: #ffffff;
  text-align: left;
  font: 500 18px/30px "Inter";
  position: relative;
} */
.home {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 362px;
  top: 103.5px;
}

.telephony {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 723px;
  top: 103.5px;
}

.hosting {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 848px;
  top: 103.5px;
}

.blog {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 950px;
  top: 103.5px;
}

.about {
  color: #da452c;
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 1024px;
  top: 103.5px;
}

.websites {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 448px;
  top: 103px;
  width: 83px;
  height: 30px;
}

.vector11 {
  position: absolute;
  left: 534px;
  top: 115px;
  overflow: visible;
}

.marketing {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: var(--body-mediumtextbold, 500 18px/160% "Inter");
  position: absolute;
  left: 584px;
  top: 103.5px;
}

.vector12 {
  position: absolute;
  left: 675px;
  top: 115px;
  overflow: visible;
}

.style-guide {
  background: #f2f2f2;
  width: 1504px;
  height: 2828px;
  position: absolute;
  left: -2015px;
  top: 0px;
  overflow: hidden;
}

.frame-18 {
  display: flex;
  flex-direction: column;
  gap: 108px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1152px;
  height: 807px;
  position: absolute;
  left: 235px;
  top: 69px;
}

.frame-37892 {
  width: 1240px;
  height: 751px;
  position: absolute;
  left: 80px;
  top: 1162px;
}

.interior-customization-with-danaanz--best-quality-with-professional-workers {
  color: #232536;
  text-align: left;
  font: 700 36px/123% "Sen";
  position: absolute;
  left: 0px;
  top: 54px;
  width: 738px;
}

.achievement {
  color: var(--grayscale-black, #000000);
  text-align: left;
  font: var(--poppins-medium-16, 500 16px/150% "Poppins");
  position: absolute;
  left: 0px;
  top: 0px;
}

.rectangle-871 {
  background: #5aa6b1;
  width: 252px;
  height: 525px;
  position: absolute;
  left: 988px;
  top: 226px;
}

.group-2169 {
  width: 156px;
  height: 441px;
  position: static;
}

.group-2166 {
  width: 150px;
  height: 109px;
  position: static;
}

._350- {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-52, 400 52px/160% "Poppins");
  position: absolute;
  left: 1048px;
  top: 266px;
}

.project-completed {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-16, 400 16px/160% "Poppins");
  position: absolute;
  left: 1039px;
  top: 349px;
}

.group-2167 {
  width: 156px;
  height: 109px;
  position: static;
}

._23- {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-52, 400 52px/160% "Poppins");
  position: absolute;
  left: 1065px;
  top: 432px;
}

.professional-teams {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-16, 400 16px/160% "Poppins");
  position: absolute;
  left: 1036px;
  top: 515px;
}

.group-2168 {
  width: 135px;
  height: 109px;
  position: static;
}

._15- {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-52, 400 52px/160% "Poppins");
  position: absolute;
  left: 1072px;
  top: 598px;
}

.years-experience {
  color: var(--grayscale-white, #ffffff);
  text-align: left;
  font: var(--poppins-regular-16, 400 16px/160% "Poppins");
  position: absolute;
  left: 1047px;
  top: 681px;
}

.mask-group {
  width: 883px;
  height: 525px;
  position: static;
}

.rectangle-876 {
  background: #d9d9d9;
  width: 883px;
  height: 525px;
  position: absolute;
  left: 46px;
  top: 226px;
}

.unsplash-tlecjidori0 {
  width: 883px;
  height: 525px;
  position: absolute;
  left: 46px;
  top: 226px;
}

.group-38589 {
  width: 675.01px;
  height: 0px;
  position: static;
}

.line-13 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 449.6px;
  height: 0px;
  position: absolute;
  left: 0px;
  top: 158px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.line-14 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 140.28px;
  height: 0px;
  position: absolute;
  left: 475.97px;
  top: 158px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.line-15 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 33.57px;
  height: 0px;
  position: absolute;
  left: 641.43px;
  top: 158px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.frame-37891 {
  width: 1440px;
  height: 924px;
  position: absolute;
  left: 0px;
  top: 170px;
}

.background {
  background: #ebf4f6;
  width: 1440px;
  height: 924px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.it-is-a-long-established-fact-that-a-reader-will-be-distracted-by-the-readable-content-of-a-page-when-looking-at-its-layout- {
  color: var(--grayscale-black, #000000);
  text-align: center;
  font: var(--poppins-regular-18, 400 18px/160% "Poppins");
  position: absolute;
  left: 429px;
  top: 190px;
  width: 582px;
}

.mask-group2 {
  width: 1200px;
  height: 513px;
  position: static;
}

.rectangle-877 {
  background: #d9d9d9;
  width: 1200px;
  height: 513px;
  position: absolute;
  left: 120px;
  top: 334px;
}

.unsplash-vbxyfxlgpjm {
  width: 1200px;
  height: 513px;
  position: absolute;
  left: 120px;
  top: 334px;
}

.group-38588 {
  width: 238px;
  height: 0px;
  position: static;
}

.line-132 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 158.53px;
  height: 0px;
  position: absolute;
  left: 629px;
  top: 154px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.line-142 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 49.46px;
  height: 0px;
  position: absolute;
  left: 796.83px;
  top: 154px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.line-152 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 11.84px;
  height: 0px;
  position: absolute;
  left: 855.16px;
  top: 154px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.about2 {
  color: #333333;
  text-align: left;
  font: 700 60px/119% "Inter";
  position: absolute;
  left: 629px;
  top: 74px;
}

.frame-37875 {
  width: 1440px;
  height: 993px;
  position: absolute;
  left: 0px;
  top: 2017px;
}

.rectangle-4706 {
  background: rgba(218, 75, 39, 0.12);
  width: 1440px;
  height: 993px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rectangle-4717 {
  opacity: 0.6000000238418579;
  position: absolute;
  left: 80px;
  top: 381px;
  overflow: visible;
}

.software-development-1-1 {
  width: 611px;
  height: 429px;
  position: absolute;
  left: 61px;
  top: 412px;
}

.here-are-a-few-reasons-why-our-customers-choose-nanoqode- {
  color: var(--primarycolors, #333333);
  text-align: left;
  font: 700 48px/138% "Inter";
  position: absolute;
  left: 82px;
  top: 122px;
}

.group-38591 {
  width: 584px;
  height: 0px;
  position: static;
}

.line-133 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 388.82px;
  height: 0px;
  position: absolute;
  left: 79px;
  top: 266px;
}

.line-143 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 121.41px;
  height: 0px;
  position: absolute;
  left: 490.87px;
  top: 266px;
}

.line-153 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 29.2px;
  height: 0px;
  position: absolute;
  left: 633.8px;
  top: 266px;
}

.design {
  color: #ffffff;
  text-align: left;
  font: 700 124px/138% "Inter";
  position: absolute;
  left: 1252px;
  top: 348px;
  transform-origin: 0 0;
  transform: rotate(90deg) scale(1, 1);
}

.rectangle-4718 {
  background: #e9e9e9;
  width: 524px;
  height: 619px;
  position: absolute;
  left: 620px;
  top: 317px;
}

.rectangle-4719 {
  background: #ffffff;
  width: 443px;
  height: 129px;
  position: absolute;
  left: 660px;
  top: 358px;
}

.rectangle-4720 {
  background: #bae3e9;
  width: 452px;
  height: 133px;
  position: absolute;
  left: 660px;
  top: 515px;
  box-shadow: 0px 14px 23px 0px rgba(90, 166, 177, 0.46);
}

.vector13 {
  position: absolute;
  left: 672px;
  top: 547px;
  overflow: visible;
}

.rectangle-4721 {
  background: #ffffff;
  width: 443px;
  height: 129px;
  position: absolute;
  left: 660px;
  top: 676px;
}

.frame2 {
  width: 52px;
  height: 53px;
  position: absolute;
  left: 679px;
  top: 714px;
  overflow: hidden;
}

.group9 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.inclusivity {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 753px;
  top: 367px;
  width: 222px;
}

.content-experience {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 755px;
  top: 521px;
  width: 227px;
  height: 29px;
}

.marketing-performance {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 753px;
  top: 694px;
  width: 222px;
}

.create-and-maintain-inclusive-and-high-quality-content--protecting-your-brand-reputation-and-reducing-the-risk-of-compliance-lawsuits-and-fines- {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 753px;
  top: 398px;
  width: 337px;
  height: 89px;
}

.identify--prioritize--and-implement-the-most-impactful-content-optimizations-so-you-can-craft-truly-impactful--and-engaging--customer-experiences- {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 755px;
  top: 553px;
  width: 343px;
  height: 95px;
}

.provide-an-intuitive-and-consolidated-approach-to-search-marketing--giving-you-deep--actionable--insights-into-how-visitors-interact-with-your-content- {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 753px;
  top: 725px;
  width: 328px;
  height: 80px;
}

.why-choose-us- {
  color: #333333;
  text-align: left;
  font: var(--heading-heading6, 600 20px/130% "Inter");
  position: absolute;
  left: 79px;
  top: 90px;
}

.frame-8 {
  background: #5aa6b1;
  border-radius: 4px;
  border: solid var(--basic, #ffffff);
  border-width: 1px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 253px;
  position: absolute;
  left: 660px;
  top: 849px;
}

.book-a-free-consult {
  color: var(--basic, #ffffff);
  text-align: left;
  font: 400 18px/160% "Inter";
  position: relative;
}

.frame3 {
  width: 63px;
  height: 58px;
  position: absolute;
  left: 675px;
  top: 394px;
  overflow: hidden;
}

.group10 {
  width: 63px;
  height: 58.01px;
  position: static;
}

.group11 {
  position: absolute;
  left: 0px;
  top: -0.01px;
  overflow: visible;
}

.frame4 {
  width: 57px;
  height: 57px;
  position: absolute;
  left: 678px;
  top: 553px;
  overflow: hidden;
}

.group12 {
  width: 57px;
  height: 57px;
  position: static;
}

.group13 {
  position: absolute;
  left: 13.28px;
  top: 3.56px;
  overflow: visible;
}

.vector24 {
  position: absolute;
  left: 18.63px;
  top: 0px;
  overflow: visible;
}

.vector25 {
  position: absolute;
  left: 14.25px;
  top: 32.39px;
  overflow: visible;
}

.vector26 {
  position: absolute;
  left: 35.64px;
  top: 32.18px;
  overflow: visible;
}

.vector27 {
  position: absolute;
  left: 6.48px;
  top: 44.17px;
  overflow: visible;
}

.vector28 {
  position: absolute;
  left: 0px;
  top: 7.15px;
  overflow: visible;
}

.vector29 {
  position: absolute;
  left: 10.79px;
  top: 48.03px;
  overflow: visible;
}

.vector30 {
  position: absolute;
  left: 8.42px;
  top: 19.11px;
  overflow: visible;
}

.group14 {
  position: absolute;
  left: 11.39px;
  top: 6.8px;
  overflow: visible;
}

.vector32 {
  position: absolute;
  left: 36.27px;
  top: 9.72px;
  overflow: visible;
}

.group15 {
  width: 11.66px;
  height: 11.66px;
  position: static;
}

.group16 {
  position: absolute;
  left: 22.67px;
  top: 18.78px;
  overflow: visible;
}

.vector34 {
  position: absolute;
  left: 40.95px;
  top: 13.19px;
  overflow: visible;
}

.vector35 {
  position: absolute;
  left: 7.12px;
  top: 24.8px;
  overflow: visible;
}

.frame-37876 {
  width: 1440px;
  height: 705px;
  position: absolute;
  left: 0px;
  top: 3010px;
}

.rectangle-47062 {
  background: rgba(218, 75, 39, 0.12);
  width: 1440px;
  height: 705px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.choose {
  color: #ffffff;
  text-align: left;
  font: 700 124px/138% "Inter";
  position: absolute;
  left: 183px;
  top: 569px;
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
}

.rectangle-47172 {
  opacity: 0.6000000238418579;
  position: absolute;
  left: 728px;
  top: 84px;
  overflow: visible;
}

.software-development-2-1 {
  width: 554px;
  height: 334px;
  position: absolute;
  left: 814px;
  top: 163px;
}

.rectangle-47182 {
  background: #e9e9e9;
  width: 524px;
  height: 619px;
  position: absolute;
  left: 296px;
  top: 7px;
}

.rectangle-47192 {
  background: #ffffff;
  width: 443px;
  height: 129px;
  position: absolute;
  left: 336px;
  top: 48px;
}

.rectangle-47202 {
  background: #bae3e9;
  width: 452px;
  height: 133px;
  position: absolute;
  left: 336px;
  top: 205px;
  box-shadow: 0px 14px 23px 0px rgba(90, 166, 177, 0.46);
}

.frame5 {
  width: 65px;
  height: 65px;
  position: absolute;
  left: 352px;
  top: 239px;
  overflow: hidden;
}

.group17 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.vector38 {
  position: absolute;
  left: 348px;
  top: 237px;
  overflow: visible;
}

.rectangle-47212 {
  background: #ffffff;
  width: 443px;
  height: 129px;
  position: absolute;
  left: 336px;
  top: 366px;
}

.dashboards {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 429px;
  top: 57px;
  width: 222px;
}

.workflow {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 431px;
  top: 226px;
  width: 227px;
  height: 29px;
}

.automation {
  color: #494949;
  text-align: left;
  font: 600 18px/156% "Inter";
  position: absolute;
  left: 429px;
  top: 384px;
  width: 222px;
}

.frame6 {
  width: 62px;
  height: 59px;
  position: absolute;
  left: 354px;
  top: 401px;
  overflow: hidden;
}

.group18 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.see-what-you-need-to-see-and-what-makes-impacts-in-your-organization---the-details-that-drive-growth--create-insights--and-develop-strategy {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 429px;
  top: 88px;
  width: 337px;
  height: 89px;
}

.when-it-s-time-to-get-the-work-done--nanoqode-provides-the-efficient-path-from-creation-to-approval-to-live {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 431px;
  top: 258px;
  width: 343px;
  height: 95px;
}

.nanoqode-platforms-offer-your-back-end-cms-to-continually-review-and-evaluate-your-site--and-guides-you-in-fixing-issues {
  color: var(--body, #494949);
  text-align: left;
  font: 400 13px/150% "Inter";
  position: absolute;
  left: 429px;
  top: 415px;
  width: 328px;
  height: 80px;
}

.frame-82 {
  background: #5aa6b1;
  border-radius: 4px;
  border: solid var(--basic, #ffffff);
  border-width: 1px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 253px;
  position: absolute;
  left: 336px;
  top: 539px;
}

.frame7 {
  width: 61px;
  height: 45px;
  position: absolute;
  left: 352px;
  top: 90px;
  overflow: hidden;
}

.group19 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.frame-37866 {
  width: 1280px;
  height: 259px;
  position: absolute;
  left: 80px;
  top: 3768px;
}

.rectangle-4515 {
  background: var(--primarycolors, #333333);
  border-radius: 4px;
  width: 1280px;
  height: 259px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.shutterstock_1820093804-1 {
  opacity: 0.18000000715255737;
  width: 1280px;
  height: 259px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.group-38479 {
  width: 1087px;
  height: 86px;
  position: static;
}

.group-38478 {
  width: 200px;
  height: 53px;
  position: static;
}

.secondary-btn {
  background: #5aa6b1;
  border-radius: 4px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  width: 200px;
  position: absolute;
  left: calc(50% - -343px);
  top: calc(50% - 26.5px);
}

.button-text {
  color: var(--basic, #ffffff);
  text-align: center;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.group-38476 {
  width: 593px;
  height: 86px;
  position: static;
}

.do-you-have-a-project-in-mind- {
  color: var(--basic, #ffffff);
  text-align: left;
  font: 700 32px/133% "Inter";
  position: absolute;
  left: 96px;
  top: 81.87px;
}

.line-19 {
  border: solid #da452c;
  border-width: 3px 0 0 0;
  width: 200px;
  height: 0px;
  position: absolute;
  left: 96px;
  top: 62px;
}

.frame-37834 {
  width: 1440px;
  height: 516px;
  position: absolute;
  left: 0px;
  top: 4080px;
}

.rectangle-8 {
  background: #5aa6b1;
  width: 1440px;
  height: 516px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.nanoqode---logo-8-1 {
  width: 181px;
  height: 49px;
  position: absolute;
  left: 80px;
  top: 46.88px;
}

.nanoqode-services-are-designed-to-meet-modern-digital-business-needs--from-getting-your-company-up-to-specs-on-security--deploying-custom-solutions--managing-you-marketing-strategy-and-many-more- {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: absolute;
  left: 80px;
  top: 127.88px;
  width: 329px;
}

.--2022-nanoqode--all-rights-reserved- {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: absolute;
  left: 1021px;
  top: 463px;
  width: 339px;
}

.group-38523 {
  width: 273px;
  height: 242px;
  position: static;
}

.frame-37794 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 512px;
  top: 53px;
}

.get-in-touch {
  color: #ffffff;
  text-align: left;
  font: var(--heading-heading6, 600 20px/130% "Inter");
  position: relative;
}

.frame-37793 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

._888-sargent-ave-winnipeg--manitoba--r3e-0c7-canada {
  color: var(--gray6, #f2f2f2);
  text-align: left;
  font: 400 18px/160% "Inter";
  position: relative;
  width: 273px;
}

.info-nanoqode-com {
  color: var(--gray6, #f2f2f2);
  text-align: left;
  font: 400 18px/20px "Inter";
  position: relative;
  width: 188px;
}

/* .-1--800--361-29612 {
  color: var(--gray6, #f2f2f2);
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
  width: 175px;
} */
.group-38527 {
  width: 119px;
  height: 291px;
  position: static;
}

.group-385232 {
  width: 119px;
  height: 291px;
  position: static;
}

.frame-377942 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 836px;
  top: 50px;
}

.learn-more {
  color: #ffffff;
  text-align: left;
  font: var(--heading-heading6, 600 20px/130% "Inter");
  position: relative;
}

.about-us {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.our-story {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.projects {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.terms-of-use {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.privacy-policy {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.contact-us {
  color: #ffffff;
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: absolute;
  left: 836px;
  top: 359px;
}

.frame-37798 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 308px;
  position: absolute;
  left: 1052px;
  top: 48px;
}

.frame-37797 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.our-newsletter {
  color: #ffffff;
  text-align: left;
  font: var(--body-largetextbold, 700 20px/34px "Inter");
  position: relative;
}

.subscribe-to-our-newsletter-to-get-our-news-delivered-to-your-inbox- {
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
  width: 307px;
}

.subscribe-to-our-newsletter-to-get-our-news-delivered-to-your-inbox--span {
  color: #ffffff;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
}

.subscribe-to-our-newsletter-to-get-our-news-delivered-to-your-inbox--span2 {
  color: var(--body, #494949);
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
}

.frame-37796 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 308px;
  position: relative;
}

.frame-37795 {
  background: #ffffff;
  border-radius: 4px;
  border: solid rgba(255, 255, 255, 0.1);
  border-width: 1px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 308px;
  height: 54px;
  position: relative;
}

.enter-your-email {
  color: var(--gray2, #999999);
  text-align: left;
  font: var(--body-reglurtextregular, 400 18px/160% "Inter");
  position: relative;
}

.primary-btn {
  background: #da4b27;
  border-radius: 4px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 200px;
  height: 54px;
  position: relative;
}

.frame8 {
  width: 194px;
  height: 21px;
  position: absolute;
  left: 80px;
  top: 349px;
  overflow: hidden;
}

.group20 {
  position: absolute;
  left: 171.07px;
  top: 3.94px;
  overflow: visible;
}

.group21 {
  position: absolute;
  left: 82.52px;
  top: 1.05px;
  overflow: visible;
}

.group22 {
  position: absolute;
  left: 87.49px;
  top: 5.65px;
  overflow: visible;
}

.vector64 {
  position: absolute;
  left: 97.9px;
  top: 3.17px;
  transform: translate(0.31px, -0.43px);
  overflow: visible;
}

.group23 {
  position: absolute;
  left: 0px;
  top: 3.23px;
  overflow: visible;
}

.group24 {
  position: absolute;
  left: 43.82px;
  top: 0px;
  overflow: visible;
}

.group25 {
  position: absolute;
  left: 127.39px;
  top: 2.44px;
  overflow: visible;
}

.line-10 {
  border: solid #ffffff;
  border-width: 2px 0 0 0;
  width: 1440px;
  height: 0px;
  position: absolute;
  left: 0px;
  top: 439px;
}

.about3 {
  color: #ffffff;
  text-align: left;
  font: 700 148px/138% "Inter";
  position: absolute;
  left: 1315px;
  top: 949px;
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
}

/* new css */

/* button style*/
.btn {
  @apply py-3 px-5 rounded font-inter;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.btn2 {
  @apply py-3 px-8 rounded font-inter;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.btn-blue {
  @apply bg-nc-blue text-white;
  min-width: 200px;
}

.btn-blue:hover {
  @apply bg-nc-orange;
}

.btn-outline {
  line-height: 28px;
  border: 1px solid #DA452C;
  @apply text-nc-orange;
}

.btn-outline:hover {
  @apply bg-nc-blue border-nc-blue text-white;
}

.btn.w-253 {
  min-width: 253px;
}

.btn-orange {
  background-color: #DA4B27;
  display: inline-block;
  width: auto;
  align-self: flex-start;
  padding-left: 57px;
  padding-right: 57px;
  border: 1px solid #DA4B27;
  transition: 0.3s ease-in-out;
}

.btn-orange:hover {
  background-color: #fff;
  color: #DA4B27;
}

.green-btn {
  color: #fff;
  background-color: #5AA6B1;
}

/*  */
/* header css */
.site-logo {
  margin-left: -21px;
}

.nc-navbar p,
.nc-navbar p~a {
  padding-left: 3px;
}

.nc-navbar a,
.nc-navbar p,
.nc-navbar>div {
  font-size: 18px;
  line-height: 28px;
  position: relative;
  padding: 10px 0;
}

.nc-navbar>div span {
  background: #DA452C;
  border-radius: 4px;
  min-width: 175px;
}

.nc-navbar>div span a {
  padding: 0 !important;
  font-size: 10px;
  line-height: 21px;
}

.nc-navbar>div span a:hover {
  color: #5AA6B1
}

/* Banner */
/* banner-sec */
.text-70 {
  font-size: 70px;
  line-height: 1;
  color: #333333;
  letter-spacing: -0.015em;
}

.nc-scroll-down {
  margin-left: -44px;
}

span.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  @apply bg-nc-blue;
}

.banner-text h4 {
  max-width: 480px;
}

/* heading */
.nc-heading {
  font-size: 48px;
  line-height: 66px;
}

.nc-sub-heading {
  font-size: 20px;
  line-height: 26px;
}

.nc-heading+img {
  /* width: 530px; */
  max-width: 100%;
}

nav p {
  position: relative;
}

/* hero */
.logo-slider {
  padding: 20px 55px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -13px !important;
  right: auto !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -13px !important;
  left: auto !important;
}

/* servies */
.nc-services {
  overflow: hidden;
}

.nc-services .nc-cards-outer {
  max-width: 1064px;
}

.nc-services .nc-cards-outer>div:first-of-type {
  border-radius: 5px 0 0 5px;
}

.nc-services .nc-cards-outer>div:last-of-type {
  border-radius: 0 5px 5px 0;
}

.nc-services .nc-img-outer {
  width: 100px;
  height: 100px;
  margin-bottom: 23px;
}

.nc-services .nc-card-text {
  color: #E5F5FF;
  font-size: 32px;
  line-height: 42px;
  @apply font-bold font-inter;
}

.nc-services .nc-cards-outer>div:hover {
  padding-top: 0 !important;
  justify-content: flex-start;
}

.nc-services .nc-cards-outer>div {
  position: relative;
  transition: 0.3s ease-in-out;
}

.nc-services .nc-cards-outer>div::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  @apply bg-nc-orange;
  border-radius: 4px 4px 0 0;
  z-index: -1;
  height: 100%;
  transition: 0.3s ease-in-out;
}

.nc-services .nc-cards-outer>div:hover::before {
  height: 267px;
}

.nc-services .nc-section-text {
  right: -25px;
  top: -68px;
  z-index: -1;
}

.nc-services .nc-section-text h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 127px;
  line-height: 138%;
  color: #FFFFFF;
  text-shadow: 0px 22px 45px rgba(0, 0, 0, 0.11);
  text-transform: uppercase;
}

.nc-info-list {
  font-size: 18px;
  line-height: 38px;
  color: #494949;
  @apply font-semibold font-inter;
}

/* website creation */
.nc-info-text {
  font-size: 16px;
  line-height: 25px;
  max-width: 433px;
}

/* step-outer */
.steps-outer h2 {
  font-size: 18px;
  line-height: 28px;
}

.steps-outer p {
  font-size: 13px;
  line-height: 19px;
}

/* design */
.design-text h3 {
  font-size: 124px;
  line-height: 171px;
  text-shadow: 0px 22px 45px rgba(0, 0, 0, 0.11);
}

/* drop message */
.drop-message {
  box-shadow: -7px 19px 50px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.bottom-form input,
.bottom-form textarea {
  border-bottom-width: 1px;
}

.bottom-shape {
  width: 214px;
}

/* clients */
.client-slides>div {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* subscribe-field */
.subscribe-field {
  padding: 12px 24px;
  @apply text-fs18 font-inter font-normal;
  color: #999999;
  border-radius: 4px;
}
 

.cs-slide-width h1 {
  padding: 22px 10px 72px;
  font-size: 51px;
  @apply font-inter text-white font-bold;
}
 

.cs-slide-width span.cs-dollar {
  font-size: 14px;
  font-weight: 300;
  top: -12px;
  position: relative;
}
 
.cs-slide-width h1 small.text-small {
  font-size: 12px;
  font-weight: 300;
}

.cs-slide-outer-layer {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.cs-slide-outer-layer .rounded-badge {
  border-radius: 0 6px 6px 0;
}

.cs-slide-outer-layer .rounded-badge h3 {
  text-transform: capitalize;
  @apply font-semibold font-inter;
}

.cs-slide-outer-layer .starting-text {
  font-size: 12px;
  @apply font-inter;
}

.cs-info-text p {
  font-size: 12px;
  @apply font-inter;
  margin-bottom: -6px;
}
.cs-info-text p img {
  width: 34px !important;
}
.cs-slide-width .owl-item{
  transition:transform 0.3s ease-in-out; 
} 
.cs-slide-width .owl-stage >div:not(.active)+div,
.cs-slide-width .owl-item.active+div+div{
  transform: scale(0.85);
}
.cs-slide-width .owl-item.active+div+div+div {
  transform: scale(0.70);
  left: -34px;
}
 

.cs-learn-more-btn {
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.cs-outer-border {
  border: 16px solid #D9D9D9;
  border-radius: 278px;
}

/* Portfolio */
.porfolio-outer h2 {
  margin: 25px 0 20px 0;
}

.porfolio-outer p {
  margin-bottom: 32px;
}

/* focus design */
.focus-design p {
  font-size: 18px;
  line-height: 27px;
  @apply font-inter;
}

.mt-cs-44 {
  margin-top: 44px !important;
}

/* heading lines */
.portfolio-line {
  width: 739px;
  max-width: 100%;
  margin-top: 10px;
}

/*  */
.logo-slider .swiper-button-next,
.logo-slider .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0) !important;
}

/* packages slider */
.packages-slider .cs-packages {
  border-radius: 22px;
  padding-bottom: 20px;
}

.packages-slider .upperlayer {
  position: relative;
  height: 200px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.packages-slider img.package-name-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.packages-slider .package-name {
  color: #43adc4;
  text-transform: uppercase;
  max-width: 140px;
  text-align: center;
  border-bottom: 3px dashed #000;
}

.packages-slider .upperlayer p {
  margin: 5px 0 0 0;
}

.packages-slider .upperlayer p span {
  color: #43adc4;
}

.package-list {
  padding: 20px;
}

.package-list li+li {
  margin-top: 6px;
}

.package-list li {
  font-size: 11px;
  color: #fff;
  padding: 5px 21px 5px 20px;
  background-color: rgb(0 0 0 /17%);
  position: relative;
}

.package-list li::before {
  content: "";
  position: absolute;
  left: 8px;
  top: 11px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  z-index: 9;
  width: 4px;
  height: 4px;
}

.package-list li::after {
  content: "";
  position: absolute;
  right: 8px;
  top: 9px;
  display: inline-block;
  z-index: 9;
  width: 9px;
  height: 5px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
}

/* .packages-slider {
  width: 700px;
  margin: 0 auto;
} */

.packages-slider .package-list+button {
  width: 100%;
  margin: 0 auto;
  max-width: calc(100% - 40px);
  background-color: #fff;
  border-radius: 20px;
  line-height: 40px;
  display: block;
}

.packages-slider .swiper-slide {
  opacity: 0;
}

.packages-slider .swiper-slide-active,
.packages-slider .swiper-slide-next+div {
  opacity: 1;
}

.packages-slider .swiper-slide-active>div {
  transform: scale(0.9) translateX(20px);
  transform-origin: right;
}

.packages-slider .swiper-slide-next {
  z-index: 99;
  opacity: 1;
}

.packages-slider .swiper-slide-next+div>div {
  transform: scale(0.9) translateX(-20px);
  transform-origin: left;
}

/*  */
/* inner banner */
.cs-inner-banner {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cs-inner-banner::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 492px;
  height: 421px;
  border-radius: 770px 0px 0px 0px;
  background-color: #DA452C;
  opacity: 0.6;
  bottom: 0;
  right: 0;
}

.host-banner.cs-inner-banner::after {
  background-color: #EBF4F6;
}

/*  */
/* seo text */
.seo-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 148px;
  line-height: 0.8;
  color: #FFFFFF;
  text-shadow: 0px 22px 45px rgb(0 0 0 / 11%);
  transform: rotate(-90deg);
  position: absolute;
  left: -25px;
  top: 150px;
}

.seo-design-swiper {
  opacity: 0.8;
}

/* .seo-packages {
  width: 100%;
} */

.seo-packages .swiper-slide-active>div {
  background-color: transparent !important;
}

.seo-packages.packages-slider .upperlayer {
  min-height: auto;
  height: auto;
  padding: 40px 0 8px 0;
}

.seo-packages .package-list {
  padding: 20px 26px;
  padding-bottom: 55px;
}

.seo-packages.packages-slider .package-name {
  color: #333;
  border: none;
}

.seo-packages .package-list li {
  font-size: 13px;
  background-color: transparent;
}

.seo-packages.packages-slider .upperlayer p {
  background-color: transparent;
  padding: 7px 50px;
  border-radius: 30px;
  font-size: 19px;
  margin-left: 40px;
  margin-top: 20px;
}

.seo-packages.packages-slider button {
  text-transform: uppercase;
}

.seo-packages .bg-img {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  object-position: top;
  width: 100%;
  height: 100%;
}

/*  */
/* social packages */
.social-packages-wrap {
  width: 700px;
  margin: 0 auto;
}

.social-packages {
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  height: 780px;
  z-index: 1;
  padding: 280px 20px 0;
}

.social-packages img.bg-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  z-index: -1;
}

.social-packages .package-name {
  text-align: center;
  text-transform: uppercase;
}

.social-package-list {
  min-height: 340px;
}

.social-price {
  font-size: 30px;
  text-align: center;
}

.social-packages button {
  margin: 0 auto;
  display: block;
  width: 178px;
  padding: 17px 0;
  margin-top: 14px;
  border-radius: 50px;
  text-transform: uppercase;
  color: #fff;
}

.social-package-list {
  min-height: 340px;
  padding: 58px 0 0 22px;
}

.social-package-list li {
  margin-bottom: 15px;
  position: relative;
}

.social-package-list li>span {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -20px;
  border: 1px solid;
  top: 4px;
}

.social-package-list li>span>span {
  width: 8px;
  height: 4px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  display: inline-block;
  transform: rotate(-45deg);
  position: absolute;
  left: 2px;
  top: 4px;
}

.social-packages-wrap .swiper-slide>div {
  transform: scale(0.9);
}

.social-packages-wrap .swiper-slide.swiper-slide-active>div {
  transform: scale(1);
}

.telephony-form input,
.telephony-form select {
  background-color: transparent !important;
}

/*  */
.ccs-cards-wrap.nc-cards-outer>div:hover::before {
  bottom: 65px;
}

.service-card:hover * {
  @apply text-white
}

/*  */
.nc-accordian .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}

.nc-accordian .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 32px;
}

.nc-accordian .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 10px 20px rgb(164 217 253 / 15%);
}

.nc-accordian .css-o4b71y-MuiAccordionSummary-content {
  margin: 0 !important;
}

.nc-accordian .css-o4b71y-MuiAccordionSummary-content p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #333333;
}

.nc-accordian .css-15v22id-MuiAccordionDetails-root {
  padding: 0 32px 40px;
}

.nc-accordian .css-15v22id-MuiAccordionDetails-root p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #494949;
}

/*  */
.hosting-banner {
  position: absolute;
  width: 621px;
  right: 0;
  bottom: 0;
}

/*  */
.letter-3 {
  letter-spacing: 3px;
}

.blog-img {
  width: 490px;
}

.blog-listing-des {
  width: calc(100% - 490px);
}

div:not(.call-line):empty {
  display: none;
}

div#root>div {
  overflow: hidden;
}

.schedule-a-call+.bottom-contact-form {
  padding-top: 0 !important;
}
.custom-nav-btn .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -215px;
}
.custom-nav-btn .owl-nav button{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAdCAYAAAB1yDbaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHmSURBVHgBlZVLUiJBEIYzq4pZjBuOQDC+JhSHG0wbim69gXgC8QTqCdAT0J5A3RgaiOAJ2geEAYaKN2DjSrrSzFYIUYHqf0M1UV92ZeZf2QgxdefNprQxJWPDPMYFVUJXETAFBDVnOPBSyd9mooqIWQBsm4TxlCv8FUyfBM/GBWzmMiUEGgDl/7FwazlTBCAuDnb0J1A08tjN5fltQCpEoBoER8ICIsJO9ECQT58F11/3mHGgspSfrNSPf9r3DW7l5jf5JwKRYGey0jiAIRroc3Npbh0V+j1w6ry+CyPUh+9zmf8EVHMFRVHBHlfn/jF4FAeM9kZGT5iA10l+9KfLtxvgKCU3REDp5cur3oIYUqExG2I7PjYbv1sNvGzSGf57etUOtV6UANzb7IR+LbrC/WpL0UKLNZLcLfnTlcbY3Pv2TJ81rrUij6N1QGE+8rUr3AtgURWiI7E9xwX4cZI0VxbWkawva+K+zwzp+9Ax9JBb2AzB7snaAhRmy/V9GHXsz/pTvtkXt31s2hPfO7+5p3vOmz6uJxvJmyrfXjrD7wEyReKJwkOhQ5q8GS6sMyxq8RCUWSYBeOBn0xd3z7GGPqdwyCmssZXbidC6z22R6co9gCv5YnSV9mO9WfTEF6drwpL+ZQpvVs6/uzdesN0AAAAASUVORK5CYII=) !important;
  background-size: 14px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 53px;
  height: 53px;
  border: 2px solid #DA452C !important;
  border-radius: 50%;
}
.custom-nav-btn .owl-nav button span{
  display: none;
}
.custom-nav-btn .owl-nav button.owl-next{
  transform: rotate(180deg);
  margin-left: 10px;
}

/* responsive */
@media(max-width:1500px) {
  /* .seo-text{
    left: -124px;
  } */
}

@media(min-width:1390px) {
  .container {
    max-width: 1390px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media(max-width:1389px) {
  .container {
    max-width: 1170px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .nc-navbar a,
  .nc-navbar p,
  .nc-navbar>div {
    font-size: 17px;
    line-height: 28px;
    position: relative;
    padding: 10px 0;
  }

  .text-70 {
    font-size: 60px;
  }

  .step-bg-text {
    right: -240px;
  }

  .design-text h3 {
    font-size: 99px;
  }

  .seo-text {
    left: -105px;
    font-size: 120px;
    top: 191px;
  }
}

@media(max-width:1199px) {
  .container {
    max-width: 990px !important;
  }

  .nc-navbar a,
  .nc-navbar p,
  .nc-navbar>div {
    font-size: 16px;
    line-height: 22px;
  }

  .nc-services .nc-section-text h4 {
    font-size: 94px;
  }

  .nc-services .nc-section-text {
    top: -50px;
  }

  .nc-info-list {
    font-size: 16px;
    line-height: 30px;
  }

  .seo-packages .swiper-slide>div,
  .packages-slider .swiper-slide-active>div {
    max-width: 302px !important;
  }

  .social-packages {
    margin-left: 0 !important;
  }
  .seo-packages.custom-nav-btn .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -155px;
}
.seo-packages.packages-slider .upperlayer {
  min-height: auto;
  height: auto;
  padding: 33px 0 8px 0;
}
.seo-packages.packages-slider .upperlayer p { 
  padding: 7px 50px; 
  font-size: 18px;
  margin-left: 25px;
  margin-top: 10px;
}
.seo-packages .package-list {
  padding: 9px 26px;
  padding-bottom: 41px;
}
.package-list li+li {
  margin-top: -0.4px;
}
}

@media(max-width:1023px) {
  .banner-text h4 {
    font-size: 23px;
    line-height: 33px;
  }
}

@media(max-width:991px) {
  .container {
    max-width: 750px !important;
  }

  .nc-services .nc-card-text {
    font-size: 23px;
    line-height: 31px;
    text-align: center;
  }

  .bottom-shape {
    width: 200px;
  }

  .packages-slider .swiper-slide-active>div {
    transform: unset;
    max-width: 230px;
    margin: 0 auto;
  }
  .mobile-menu {
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    top: calc(100% + 10px);
}
.mob-menu-inner {
  width: 100%;
}
.mob-menu-inner>*+* {
  border-top: 1px solid #ccc;
}
.mob-menu-inner>* {
  padding: 8px 0 !important;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mob-menu-inner>p>div{
  position: relative;
  width: 100%;
    top: unset;
    left: unset;
    right: unset;
    margin-left: 0 !important;
    margin-top: 3px;
} 
.seo-packages.packages-slider .upperlayer {
  padding: 21px 0 4px 0;
}
.seo-packages .package-list li {
  font-size: 11px; 
}
.seo-packages .package-list {
  padding: 9px 26px;
  padding-bottom: 44px;
}
.packages-slider .package-list+button{
  line-height: 30px;
}
}

@media(max-width:767px) {
  .container {
    max-width: 570px !important;
  }

  .banner-text h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .nc-services .nc-card-text {
    font-size: 17px;
    line-height: 26px;
    text-align: center;
  }

  .nc-info-text {
    font-size: 15px;
    line-height: 24px;
    max-width: 100%;
  }

  .logo-slider {
    padding: 20px 25px !important;
  }
  .seo-packages .package-list {
    padding: 19px 26px;
    padding-bottom: 37px;
}

}

@media(max-width:575px) {
  .container {
    max-width: 100% !important;
  }

  .btn {
    font-size: 15px;
    line-height: 20px;
  }

  .btn-blue {
    min-width: unset;
    white-space: nowrap;
  }

  .nc-accordian .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 22px;
  }

  .nc-accordian .css-15v22id-MuiAccordionDetails-root {
    padding: 0 22px 22px;
  }

  .cs-inner-banner::after {
    width: 322px;
    height: 321px;
  }
}